import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//component
import Seo from '../components/SEO'

//scss
import '../scss/page/productType.scss'
//data
const getData = graphql`
  {
    type: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        title
        slug
        resume {
          resume
        }
        colorVar {
          title
          slug
        }
        img {
          title
          gatsbyImageData(
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
const TemplateProductType = ({ pageContext, location }) => {
  const data = useStaticQuery(getData)
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />

      <div className='productType'>
        {data.type.nodes.map((item, index) => {
          return (
            <div key={index} className='product'>
              <div className='container'>
                <Link to={`/produits/${item.slug}`}>
                  <GatsbyImage
                    image={item.img.gatsbyImageData}
                    alt={item.img.title}
                  />
                </Link>
                <div className='content'>
                  <h1>
                    <Link to={`/produits/${item.slug}`}>{item.title}</Link>
                  </h1>
                  <p>{item.resume.resume}</p>
                  {item.colorVar !== null ? (
                    <ul>
                      {item.colorVar.map((itm, idx) => {
                        return (
                          <li key={idx}>
                            <Link to={`/produits/${item.slug}/${itm.slug}`}>
                              {item.title} {itm.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                  <Link to={`/produits/${item.slug}`} className='button'>
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TemplateProductType
